import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/auth";



export function SignIn() {
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');

  async function handleForm(e: FormEvent) {
    e.preventDefault();

    try {
      await signIn({
        document: document.replace(/[/,.,-\s]/g, ""),
        password
      });

      navigate("/");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Entrar - Backoffice</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleForm}>
              <div>
                <label htmlFor="document" className="block text-sm font-medium text-gray-700">
                  Documento
                </label>
                <div className="mt-1">
                  <input
                    id="document"
                    name="document"
                    type="text"
                    required
                    value={document}
                    onChange={input => setDocument(input.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Senha
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={input => setPassword(input.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>


              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Entrar
                </button>
              </div>
            </form>


          </div>
        </div>
      </div>
    </>
  )
}