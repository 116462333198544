import { Fragment } from "react";

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Link, Outlet } from "react-router-dom";
import { XMarkIcon, Bars2Icon } from '@heroicons/react/24/outline'; // Replace BarsIcon with the correct name


import { cn } from "../utils/cn";
import darkLogo from '../assets/dark-logo-nobe.png'
import ceo from '../assets/ceo.jpeg'


export function Layout() {
  const user = {
    name: 'Diretoria Nobe',
    email: 'diretoria.comercial@nobesistemas.com.br',
    imageUrl: ceo,
  }

  const navigation = [
    // { name: 'Dashboard', href: '/' },
    { name: 'Contas', href: '/accounts' },
    { name: 'Transferências', href: '/transfers' },
    { name: 'Relatórios', href: '/reports' },
  ]

  return (
    <div className="h-full">
      <Disclosure as="header" className="bg-white shadow">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
              <div className="relative h-16 flex justify-between">
                <div className="relative z-10 px-2 flex lg:px-0">
                  <div className="flex-shrink-0 flex items-center font-medium ">
                    <img
                      className="block h-12 w-auto"
                      src={darkLogo}
                      alt="Workflow"
                    />
                    <p className="mt-[30px] ml-2 text-xl italic">
                      <span className="mr-1">-</span> Backoffice
                    </p>
                  </div>
                </div>

                {/* Mobile menu button */}
                <div className="relative z-10 flex items-center lg:hidden">
                  <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                    <span className="sr-only">Abrir menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars2Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="flex-shrink-0 relative ml-4">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                        <span className="sr-only">Open user menu</span>
                        <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute font-bold right-0 text-center mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        <Menu.Item key={'Sair'}>
                          {({ active }) => (
                            <a
                              href='/logout'
                              className={cn(
                                active ? 'bg-gray-100' : '',
                                'block py-2 px-4 text-sm text-gray-700'
                              )}
                            >
                              Sair
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>

            <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="pt-2 pb-3 px-2 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-200 pt-4 pb-3">
                <div className="px-4 flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">{user.name}</div>
                    <div className="text-sm font-medium text-gray-500">{user.email}</div>
                  </div>
                </div>
                <div className="mt-3 px-2 space-y-1">
                  <Disclosure.Button
                    key="sair"
                    as="a"
                    href='/logout'
                    className="block rounded-md py-2 text-base w-10 px-2 font-medium hover:bg-gray-50 text-gray-900"
                  >
                    Sair
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      <main className="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
        <Outlet />
      </main>
    </div>
  );
}