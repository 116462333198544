const setup = [
  {
    name: "NobePag",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6IjcxZjc4N2M1LWNhMDUtNDA5ZC1iNzFjLTBlODU2ODg2OTJjZSIsImlhdCI6MTY2NjI3MDQ2NSwiZXhwIjoxNjY4ODYyNDY1LCJzdWIiOiJlY2I4ZWQ3ZS1kNDdmLTRkNzAtODMxMi0xOTc5NGVkYzc5NWMifQ.3kBW_cxGXTruWnOO0MQZD37OW04qqvVZhfv19cI_M6Q",
    host: "backoffice.stric.com.br",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
  },
  {
    name: "Nobe Bank",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6IjcxZjc4N2M1LWNhMDUtNDA5ZC1iNzFjLTBlODU2ODg2OTJjZSIsImlhdCI6MTY2NjI3MDQ2NSwiZXhwIjoxNjY4ODYyNDY1LCJzdWIiOiJlY2I4ZWQ3ZS1kNDdmLTRkNzAtODMxMi0xOTc5NGVkYzc5NWMifQ.3kBW_cxGXTruWnOO0MQZD37OW04qqvVZhfv19cI_M6Q",
    host: "backoffice.stric.com.br",
    client_id: "e1c81c9f-b51a-4dcb-ad89-bb9af4761965",
  },
  {
    name: "Pedzap",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6ImU3ZTNjMWI3LTE4OTUtNDY4ZC04ZjgzLWRiODRiYWFmYzhlNCIsImlhdCI6MTY2MTE4Mzk0NywiZXhwIjoxNjYzNzc1OTQ3LCJzdWIiOiI5NjE3ZWE0ZC0wYTcwLTQ0NTAtOTZiMy0zZTRhZmZmMmRhOGYifQ.le1O1NMwCWXY442gmE7li39xN_MjH5EI5thPqF3-_Dc",
    host: "backoffice-pedzap.ajiopay.com.br",
    client_id: "c53bc6ad-7450-4774-9ee6-da1898936791",
  },
  {
    name: "AjioPay",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6IjYwZmFjYjE2LTlmZWEtNGU3Yy1iYTYwLTVmZThiNDBlMTA2MyIsImlhdCI6MTY2MTE4NDE1NywiZXhwIjoxNjYzNzc2MTU3LCJzdWIiOiJmNjhlMTg1MC0yNzhiLTQwMzEtYjhmYy04NGY0N2ViNGY3NTEifQ.fAUeEMOUcvWMDTkXVYH2pEtkzc0GCzbZHZG-fCRAg0g",
    host: "backoffice-ajio.ajiopay.com.br",
    client_id: "ecd982cc-f3b8-4331-a072-88f6366fa41b",
  },
  {
    name: "Icard",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6Ijk3ZDUwYzk5LTAzMGMtNGZkYy05MTEzLTZmNTVjNmZkYTg3YSIsImlhdCI6MTY2NjI3MDQxNiwiZXhwIjoxNjY4ODYyNDE2LCJzdWIiOiJmNjhlMTg1MC0yNzhiLTQwMzEtYjhmYy04NGY0N2ViNGY3NTEifQ.vh67pTdUp7MgsT7j6n-XHDk6Q8O7f0RnUh2dvdh33Z0",
    host: "v2-backofficce.vercel.app",
    client_id: "d2afc12c-bc27-440c-96c2-88e006c92ab2",
  },
  {
    name: "VF Bank",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwcm92aWRlciI6Ijk3ZDUwYzk5LTAzMGMtNGZkYy05MTEzLTZmNTVjNmZkYTg3YSIsImlhdCI6MTY2NjI3MDQxNiwiZXhwIjoxNjY4ODYyNDE2LCJzdWIiOiJmNjhlMTg1MC0yNzhiLTQwMzEtYjhmYy04NGY0N2ViNGY3NTEifQ.vh67pTdUp7MgsT7j6n-XHDk6Q8O7f0RnUh2dvdh33Z0",
    host: "backoffice-stric.stric.com.br",
    client_id: "a9d32ac4-de8d-4f66-9c21-d1878adafa8d",
  },
];

export default setup;
