import React, { createContext, useCallback, useState, useContext, useEffect } from 'react';

import { api, CommonHeaderProperties } from '../services/api';

import setup from '../setup';
const clientConfig = setup.find(cl => window.location.host.includes(cl.host)) || setup[0];

interface Account {
  id: string;
  name: string;
  document: string;
  balance: number;
  bank: string;
  branch: string;
  account: string;
  accountDigit: string;
  status: string;
  type: string;
  pin: boolean;
  email: string;
  isPool: boolean;
  havePermission: boolean;
}

interface AuthState {
  token: string;
  account: Account;
}

interface SignInCredentials {
  document: string;
  password: string;
}

interface RequestSignIn {
  document: string;
  password: string;
}

interface AuthContextData {
  account: Account;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  refresh: () => void;
  // updateBalance(balance: number): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}


export function AuthProvider({ children }: AuthProviderProps) {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@backofficev2:token');
    const account = localStorage.getItem('@backofficev2:account') as string;


    if (token && account) {
      const accountData = JSON.parse(account);

      api.defaults.headers = {
        Authorization: `Bearer ${token}`,
        account: accountData.id,
      } as CommonHeaderProperties;

      return { token, account: accountData };
    }

    return {} as AuthState;
  });



  async function getAccount() {
    const token = window.localStorage.getItem('@backofficev2:token');
    if (token) {
      try {
        api.defaults.headers = {
          Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;

        const { data } = await api.get('/accounts');

        setData({ token, account: data });
      } catch(err) {
        signOut();
      }
    }
  }

  function refresh() {
    getAccount();

    setTimeout(() => {
      getAccount();
    }, 2000);

    setTimeout(() => {
      getAccount();
    }, 10000);

    setTimeout(() => {
      getAccount();
    }, 60000);
  }


  useEffect(() => {
    getAccount();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const signOut = useCallback(() => {
    localStorage.removeItem('@backofficev2:token');
    localStorage.removeItem('@backofficev2:account');

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(async ({ document, password }: RequestSignIn) => {
    window.localStorage.removeItem('@backofficev2:status');
    window.localStorage.removeItem('@backofficev2:id');

    const response = await api.post('/accounts/session', {
      document,
      password,
    }, {
      headers: {
        client: clientConfig.client_id,
      }
    });


    const { access_token: token } = response.data;


    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;

    const { data } = await api.get("/accounts");

    if (!data.havePermission) {
      signOut();
      throw new Error("Invalid.");
    }
    
    localStorage.setItem('@backofficev2:token', token);
    localStorage.setItem('@backofficev2:account', JSON.stringify(data));

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
      account: data.id,
    } as CommonHeaderProperties;

    setData({ token, account: data });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <AuthContext.Provider
      value={{ account: data.account, signIn, signOut, refresh }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}



