import { Transition } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { endOfMonth, startOfMonth, subHours } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "../components/Skeleton";
import { api } from "../services/api";
import { currencyFormat } from "../utils/currencyFormat";

import DatePicker from "react-datepicker";




import "react-datepicker/dist/react-datepicker.css";



import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { cn } from "../utils/cn";

interface IItems {
  id: string;
  description: string;
  balanceAfter: number;
  date: string;
  status: string;
  type: string;
  credit: boolean;
  amount: number;
}
export interface Transaction {
  id: string
  credit: boolean
  amount: number
  type: string
  typeId: string
  description: string
  status: string
  balanceAfter: number
  date: string
}

export interface Detail {
  id: string
  credit: boolean
  amount: number
  description: string
  rateValue: number
  consignorName: string
  consignorDocument: string
  consignorBankNumber: string
  consignorBankBranch: string
  consignorBankAccount: string
  consignorBankAccountDigit: string
  beneficiaryName: string
  beneficiaryDocument: string
  beneficiaryBankNumber: string
  beneficiaryBankBranch: string
  beneficiaryBankAccount: string
  beneficiaryBankAccountDigit: string
  status: string
  date: string
}


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export function AccountsDetailsTransactions() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [items, setItems] = useState<IItems[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);



  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const dateEnd = subHours(endDate, 3);
    setLoading(true);
    api.get(`/backoffice/extracts/${id}?start=${startDate}&end=${dateEnd}&page=${currentPage}`).then(({ data }) => {
      setTotalPages(data.total_pages + 1);
      setCurrentPage(Number(data.current_page));
      setItems(data.items);
      setLoading(false);
    })
  }, [currentPage, startDate, endDate, id]);

  function handleExport() {
    setIsExporting(true);
    function getFileToDownload(apiUrl: string) {
      return api.get(apiUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    getFileToDownload(`/backoffice/extracts/${id}?start=${startDate}&end=${endDate}&page=${currentPage}&pdf=true`)
      .then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'extract.pdf'
        link.click()
        setIsExporting(false);
        // setOpenNotification({title: 'Extrato Exportado', type: 'success', notification: 'Extrato exportado com sucesso'});
      }).catch(err => {
        setIsExporting(false);
        // setOpenNotification({title: 'Erro', type: 'error', notification: 'Não foi possível exportar o extrato'});
      })
  }

  function handleExportExcel() {
    setIsExporting(true);
    function getFileToDownloadExcel(apiUrl: string) {
      return api.get(apiUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }

    getFileToDownloadExcel(`/backoffice/extracts/${id}?start=${startDate}&end=${endDate}&page=${currentPage}&excel=true`)
      .then(response => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'extract.xlsx'
        link.click()
        setIsExporting(false);
        // setOpenNotification({title: 'Extrato Exportado', type: 'success', notification: 'Extrato exportado com sucesso'});
      }).catch(err => {
        setIsExporting(false);
        // setOpenNotification({title: 'Erro', type: 'error', notification: 'Não foi possível exportar o extrato'});
      })
  }



  function getType(type: string): string {
    if (type === 'PIX') {
      return "Pix";
    }

    if (type === 'TED') {
      return "Transferência";
    }

    if (type === 'P2P') {
      return "Transferência";
    }

    if (type === 'BILLET') {
      return "Boleto";
    }

    if (type === 'PAYMENT') {
      return "Pagamento";
    }

    if (type === 'INITIAL') {
      return '';
    }

    return type;
  }

  function handlePrevious() {
    if (currentPage + 1 > 1) {
      setCurrentPage(Number(currentPage - 1));
    }
  }

  function handleNext() {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(Number(currentPage + 1));
    }
  }




  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
        <nav className="space-y-1">
          <Link
            to={`/accounts/${id}`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          >
            <span className="truncate">Dados gerais</span>
          </Link>
          <Link
            to={`/accounts/${id}/extract`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          // aria-current={item.current ? 'page' : undefined}
          >
            {/* <item.icon
                className={cn(
                  item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              /> */}
            <span className="truncate">Transações (Extrato)</span>
          </Link>
          <Link
            to={`/accounts/${id}/tax`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          // aria-current={item.current ? 'page' : undefined}
          >
            {/* <item.icon
            className={cn(
              item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
              'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
            )}
            aria-hidden="true"
          /> */}
            <span className="truncate">Limites operacionais</span>
          </Link>
        </nav>
      </aside>

      <div className={cn("space-y-6 sm:px-6 lg:px-0 w-ful", "lg:col-span-10")}>
        <section aria-labelledby="payment-details-heading">
          <>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 sm:p-6">
                <Skeleton isActive={loading} repeat={3} />
                {!loading && (
                  <>
                    <div>
                      <h2 id="payment-details-heading" className="flex justify-between text-lg leading-6 font-medium text-gray-900">
                        Extrato
                      </h2>
                      <p className="mt-1 text-sm text-gray-500">
                        {/* Update your billing information. Please note that updating your location could affect your tax
                    rates. */}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-xl">Período</h3>
                      <span>Selecione um período para filtrar</span>
                      <div className="flex w-40">
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={startDate}
                          onChange={(date: Date) => setStartDate(date)}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="px-2 py-1">até</span>
                        <DatePicker
                          className="bg-gray-300 rounded px-2 py-1"
                          selected={endDate}
                          onChange={(date: Date) => setEndDate(date)}
                          locale="pt"
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>

                    </div>
                    <div className="sm:flex sm:items-center">
                      <div className="sm:flex-auto">
                      </div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <button
                          type="button"
                          onClick={handleExport}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mr-3"
                        >
                          {isExporting ? 'Exportando...' : 'Exportar PDF'}
                        </button>

                        <button
                          type="button"
                          onClick={handleExportExcel}
                          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                        >
                          {isExporting ? 'Exportando...' : 'Exportar Excel'}
                        </button>
                      </div>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Data
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Tipo
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Descrição
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Valor
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {items.map((item: IItems, index) => (
                                  <tr key={item.id} className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {Intl.DateTimeFormat("pt-BR").format(new Date(item.date))}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {getType(item.type)}
                                      {item.credit ? ' - Crédito' : ' - Débito'}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      <div className="truncate max-w-md" title={item.description}>
                                        {item.description === 'TRANSFERÊNCIA PARA PREFEITURA DE SALINAS SALINAS' ? 'TRANSFERÊNCIA PARA MESMA TITULARIDADE - CAIXA' : item.description}
                                      </div>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      <span className={`${item.credit ? 'text-green-500' : 'text-red-500'}`}>
                                        {!item.credit && item.amount > 0 && '-'} {currencyFormat(item.amount)}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="flex items-center justify-center mt-5 mb-5">
                              <ChevronDoubleLeftIcon onClick={handlePrevious} className="mr-3 cursor-pointer w-5 h-5 text-gray-500" />
                              <span>Página {currentPage + 1} de {totalPages}</span>
                              <ChevronDoubleRightIcon onClick={handleNext} className="ml-3 cursor-pointer w-5 h-5 text-gray-500" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              aria-live="assertive"
              className="z-50 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
            >
              <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                  show={show}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          <p className="text-sm font-medium text-gray-900">Configurações salvar</p>
                          <p className="mt-1 text-sm text-gray-500">As configurações de taxas foram alteradas.</p>
                        </div>
                        <div className="ml-4 flex-shrink-0 flex">
                          <button
                            type="button"
                            className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => {
                              setShow(false)
                            }}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </>
        </section>
      </div>
    </div>
  );
}