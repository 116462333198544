import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { AccountsDetailsTransactions } from "../pages/accounts-details-transactions";
import { Accounts } from "../pages/accounts";
import { AccountsDetails } from "../pages/accounts-details";
import { AccountsDetailsTax } from "../pages/accounts-details-tax";

import { Layout } from "../pages/layout";
import { Logout } from "../pages/logout";
import { SignIn } from '../pages/signIn';
import { Transfers } from "../pages/transfers";
import { Reports } from "../pages/reports";


function RequireAuth({ children }: { children: JSX.Element }) {
  let { account } = useAuth();
  let location = useLocation();


  if (!account) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

const RoutesComponent: React.FC = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="signin" element={<SignIn />} />

        <Route path="/" element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }>

          <Route index element={<Accounts />} />
          <Route path="accounts" element={<Accounts />} />
          <Route path="accounts/:id" element={<AccountsDetails />} />
          <Route path="accounts/:id/tax" element={<AccountsDetailsTax />} />
          <Route path="accounts/:id/extract" element={<AccountsDetailsTransactions />} />

          <Route path="transfers" element={<Transfers />} />
          <Route path="reports" element={<Reports />} />

          <Route path="/logout" element={<Logout />} />
        </Route>

      </Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;

