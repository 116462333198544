import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, ChevronRightIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Skeleton } from "../components/Skeleton";
import { api } from "../services/api";
import { currencyFormat } from "../utils/currencyFormat";
import { documentFormatted } from "../utils/documentFormatted";

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { cn } from "../utils/cn";


type AccountProps = {
  id: string;
  name: string;
  balance: number;
  document: string;
  email: string;
  monthlyIncome: number;
  phone: string;
};

type AddressProps = {
  city: string;
  complement: string;
  date: string;
  id: string
  neighborhood: string
  number: string
  state: string
  street: string
  zipCode: string
};

type DocumentsProps = {
  id: string;
  originalName: string;
  name: string;
  status: string;
  type: string;
  createdAt: string;
  base64: string;
}

export function AccountsDetails() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState<AccountProps>({} as AccountProps);
  const [address, setAddress] = useState<AddressProps>({} as AddressProps);
  const [documents, setDocuments] = useState<DocumentsProps[]>([] as DocumentsProps[]);

  const [open, setOpen] = useState(false);
  const [baseString, setBaseString] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    api.get(`/backoffice/accounts/${id}`).then(({ data }) => {
      setAccount(data.account);
      setAddress(data.address);
      setDocuments(data.documents);
      setLoading(false);
    })
  }, [id]);


  function showDocument(base64: string) {
    setOpen(true);
    setBaseString(base64);
  }

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
        <nav className="space-y-1">
          <Link
            to={`/accounts/${id}`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          // aria-current={item.current ? 'page' : undefined}
          >
            {/* <item.icon
                className={cn(
                  item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              /> */}
            <span className="truncate">Dados gerais</span>
          </Link>
          <Link
            to={`/accounts/${id}/extract`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          // aria-current={item.current ? 'page' : undefined}
          >
            {/* <item.icon
                className={cn(
                  item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              /> */}
            <span className="truncate">Transações (Extrato)</span>
          </Link>
          <Link
            to={`/accounts/${id}/tax`}
            className={cn(
              'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
              'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
            )}
          // aria-current={item.current ? 'page' : undefined}
          >
            {/* <item.icon
                className={cn(
                  item.current ? 'text-blue-500' : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                )}
                aria-hidden="true"
              /> */}
            <span className="truncate">Limites operacionais</span>
          </Link>
        </nav>
      </aside>

      <div className={cn("space-y-6 sm:px-6 lg:px-0 w-ful", "lg:col-span-10")}>
        <section aria-labelledby="payment-details-heading">
          <>
            <form action="#" method="POST">
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <Skeleton isActive={loading} repeat={3} />
                  {!loading && (
                    <>
                      <div>
                        <h2 id="payment-details-heading" className="flex justify-between text-lg leading-6 font-medium text-gray-900">
                          {account.name}
                          <span>
                            {currencyFormat(account.balance)}
                          </span>
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {/* Update your billing information. Please note that updating your location could affect your tax
                          rates. */}
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Nome
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            disabled
                            value={account.name}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                            Documento
                          </label>
                          <input
                            type="text"
                            name="document"
                            id="document"
                            disabled
                            value={documentFormatted(account.document)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                            E-mail
                          </label>
                          <input
                            type="text"
                            name="email"
                            id="email"
                            disabled
                            value={account.email}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                            Renda mensal
                          </label>
                          <input
                            type="text"
                            name="expiration-date"
                            id="expiration-date"
                            disabled
                            value={account.monthlyIncome}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            placeholder="MM / YY"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="security-code"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            <span>Celular</span>
                          </label>
                          <input
                            type="text"
                            name="security-code"
                            id="security-code"
                            disabled
                            value={account.phone}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                      </div>
                    </>
                  )}
                </div>

                {/* {!loading && (
                  <div className="px-4 py-3 bg-white text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Salvar
                  </button>
                </div>
                )} */}
              </div>

              <div className="shadow sm:rounded-md sm:overflow-hidden mt-10">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <Skeleton isActive={loading} repeat={3} />
                  {!loading && address && address?.street && (
                    <>
                      <div>
                        <h2 id="payment-details-heading" className="flex justify-between text-lg leading-6 font-medium text-gray-900">
                          Endereço
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {/* Update your billing information. Please note that updating your location could affect your tax
                          rates. */}
                        </p>
                      </div>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Endereço
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            disabled
                            value={address.street}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                            Número
                          </label>
                          <input
                            type="text"
                            name="expiration-date"
                            id="expiration-date"
                            value={address.number}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            placeholder="MM / YY"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="security-code"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            <span>Complemento</span>
                          </label>
                          <input
                            type="text"
                            name="security-code"
                            id="security-code"
                            value={address.complement}
                            autoComplete="cc-csc"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-6 grid grid-cols-5 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                            Bairro
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            disabled
                            value={address.neighborhood}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                            Cidade
                          </label>
                          <input
                            type="text"
                            name="expiration-date"
                            id="expiration-date"
                            value={address.city}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            placeholder="MM / YY"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="security-code"
                            className="flex items-center text-sm font-medium text-gray-700"
                          >
                            <span>Estado</span>
                          </label>
                          <input
                            type="text"
                            name="security-code"
                            id="security-code"
                            value={address.state}
                            autoComplete="cc-csc"
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-1">
                          <label htmlFor="expiration-date" className="block text-sm font-medium text-gray-700">
                            CEP
                          </label>
                          <input
                            type="text"
                            name="expiration-date"
                            id="expiration-date"
                            value={address.zipCode}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                            placeholder="MM / YY"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* {!loading && (
                  <div className="px-4 py-3 bg-white text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Salvar
                  </button>
                </div>
                )} */}
              </div>

              <div className="shadow sm:rounded-md sm:overflow-hidden mt-10">
                <div className="bg-white py-6 px-4 sm:p-6">
                  <Skeleton isActive={loading} repeat={3} />
                  {!loading && (
                    <>
                      <div>
                        <h2 id="payment-details-heading" className="flex justify-between text-lg leading-6 font-medium text-gray-900">
                          Documentos
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {/* Update your billing information. Please note that updating your location could affect your tax
                          rates. */}
                        </p>
                      </div>

                      <div className="mt-5">
                        <div className="bg-white shadow overflow-hidden sm:rounded-md">
                          <ul className="divide-y divide-gray-200">
                            {documents.map((document) => (
                              <li key={document.id} onClick={() => showDocument(document.base64)} className="cursor-pointer">
                                <span className="block hover:bg-gray-50">
                                  <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                      {/* <div className="flex-shrink-0">
                                      <img className="h-12 w-12 rounded-full" src={document.originalName} alt="" />
                                    </div> */}
                                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                          <p className="text-sm font-medium text-indigo-600 truncate">{document.name}</p>
                                          <p className="mt-2 flex items-center text-sm text-gray-500">
                                            <span className="truncate">{document.type}</span>
                                          </p>
                                        </div>
                                        <div className="hidden md:block">
                                          <div>
                                            <p className="text-sm text-gray-900">
                                              Adicionado em <time dateTime={document.createdAt}>{format(new Date(document.createdAt), 'dd/MM/yyyy')}</time>
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-gray-500">
                                              {document.status === 'ACTIVE' ? (
                                                <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
                                              ) : (
                                                <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
                                              )}
                                              {document.status}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                  </div>
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {/* {!loading && (
                  <div className="px-4 py-3 bg-white text-right sm:px-6">
                  <button
                    type="submit"
                    className="bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  >
                    Salvar
                  </button>
                </div>
                )} */}
              </div>
            </form>
          </>
        </section>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            <CopyToClipboard text={baseString}
                              onCopy={() => console.log('copied')}>
                              <button>Copiar base64</button>
                            </CopyToClipboard>
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 break-all">
                        {/* <SyntaxHighlighter language="javascript" style={docco} wrapLongLines={true}>
                      {baseString}
                    </SyntaxHighlighter> */}

                        <div className="absolute inset-0 px-4 sm:px-6">
                          <div className="h-full border-2 border-dashed border-gray-200" aria-hidden="true" />
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>


  );
}