import { Fragment, FormEvent, useEffect, useState } from "react";
import { Skeleton } from "../components/Skeleton";
import { api } from "../services/api";
import { currencyFormat } from "../utils/currencyFormat";
import { dateFormat } from "../utils/dateFormat";
import { documentFormatted } from "../utils/documentFormatted";
import { cn } from "../utils/cn";
import IntlCurrencyInput from "react-intl-currency-input";
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/20/solid'

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};


export interface AccountProps {
  id: string
  accountId: string
  externalId: string
  credit: boolean
  url: string
  amount: number
  identifier: string
  description: string
  rateValue: number
  consignorName: string
  consignorDocument: string
  consignorBankNumber: string
  consignorBankBranch: string
  consignorBankAccount: string
  consignorBankAccountDigit: string
  beneficiaryName: string
  beneficiaryDocument: string
  beneficiaryBankNumber: string
  beneficiaryBankBranch: string
  beneficiaryBankAccount: string
  beneficiaryBankAccountDigit: string
  status: string
  internalProcess: string
  statusCode: number
  createdAt: string
  updatedAt: string
}

export interface MetaProps {
  current_page: number
  total_pages: number
  total_items: number
  total_items_page: number
}

export function Transfers() {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState<AccountProps[]>([] as AccountProps[]);
  // const [meta, setMeta] = useState<MetaProps>({} as MetaProps);

  const [totalPages, ] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [feeBillet, setFeeBillet] = useState(0);
  const [show, setShow] = useState(false);



  const [tabs, setTabs] = useState([
    { name: 'Em Aberto', href: '#', current: true },
    { name: 'Processadas', href: '#', current: false },
    { name: 'Liquidadas', href: '#', current: false },
  ])


  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api.get(`/backoffice/transfers-santander?santander=true&page=${currentPage}`).then(({ data }) => {
      const current = tabs.find(tab => tab.current);

      setFeeBillet(data.returnToIugu[0].mktPlaceId || 0);

      if (current) {
        if (current.name === 'Em Aberto') {
          setAccounts(data.transfers);
        }
        if (current.name === 'Processadas') {
          setAccounts(data.tranfersPre);
        }
        if (current.name === 'Liquidadas') {
          setAccounts(data.tranfersDone);
        }
      } else {
        setAccounts(data.transfers);
      }
      setLoading(false);
    })
  }, [currentPage, tabs]);


  function handleTab(name: string) {
    const newTabs = tabs.map(tab => {
      return {
        name: tab.name,
        href: '#',
        current: tab.name === name,
      };
    });

    setTabs(newTabs);
  }


  function getStatus(status: string) {
    if (status === 'ACTIVE') {
      return {
        label: "Ativo",
        color: "green",
      }
    }

    if (status === "BLOCKED") {
      return {
        label: "Bloqueado",
        color: "red"
      }
    }

    if (status === 'WAITING_ANALYSIS') {
      return {
        label: "Aguardando análise",
        color: "gray",
      }
    }

    if (status === 'UNDER_ANALYSIS') {
      return {
        label: "Em análise",
        color: "gray"
      }
    }

    if (status === 'WAITING_DOCUMENTS') {
      return {
        label: "Aguardando documentos",
        color: "gray",
      }
    }

    if (status === 'WAITING_ADDRESS') {
      return {
        label: "Aguardando endereço",
        color: "gray"
      }
    }

    return {
      label: status,
      color: "green"
    }
  }

  async function handleUpdateIugu() {
    const newNumber = Math.floor(feeBillet);
    setFeeBillet(newNumber)

    setLoading(true);

    api.get(`/backoffice/transfers-santander?santander=true&page=0&updated=true&number=${newNumber}`).then(({ data }) => {      
      setLoading(false);
      setShow(true);
    })

  }


  function handlePrevious() {
    if (currentPage + 1 > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function handleNext() {
    if (currentPage + 1 < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }


  return (
    <>
    <div className="px-4 sm:px-6 lg:px-8">
      <h1 className="text-xl font-semibold text-gray-900">Transferências</h1>
      <div className="flex justify-end">
        <div className="col-span-2 sm:col-span-2">
          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
            Valor a Retornar para Iugu
          </label>
         <div className="flex items-center gap-4">
         <IntlCurrencyInput
            className="mt-1 min-w-[150px] block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
            currency="BRL"
            config={currencyConfig}
            value={feeBillet}
            onChange={(_event: FormEvent, value: number) => setFeeBillet(value)}
          />
          <button 
            type="button"
            className="mt-2 w-full rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 "
            onClick={handleUpdateIugu}>Salvar</button>
         </div>
        </div>
      </div>
      <div className="sm:flex sm:items-center mt-5">
        <div className="sm:flex-auto">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              // defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="flex space-x-4" aria-label="Tabs">
                {tabs.map((tab) => (
                  <span
                    key={tab.name}
                    onClick={() => handleTab(tab.name)}
                    className={cn(
                      tab.current ? 'bg-indigo-100 text-indigo-700' : 'text-gray-500 hover:text-gray-700',
                      'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </span>
                ))}
              </nav>
            </div>
          </div>


          {/* <p className="mt-2 text-sm text-gray-700">
            Uma lista de todos as contas, incluindo nome, título, e-mail e função.
          </p> */}
        </div>
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add user
          </button>
        </div> */}
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <Skeleton isActive={loading} repeat={3} space={4} />
              {!loading && (
                <>
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Conta
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Beneficiário
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Banco Agência/Conta
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Valor
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Data
                        </th>
                        {/* <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th> */}
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {accounts.map((account) => (
                        <tr key={account.id}>
                          <td className="whitespace-nowrap py-4 pr-3 text-sm sm:pl-6">
                            <div className="flex items-center">
                              {/* <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={person.image} alt="" />
                          </div> */}
                              <div className="ml-4 w-64">
                                <div className="font-medium text-gray-900 truncate">{account.consignorName}</div>
                                <div className="text-gray-500">{documentFormatted(account.consignorDocument)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="ml-4 w-64">
                              <div className="font-medium text-gray-900 truncate">{account.beneficiaryName}</div>
                              <div className="text-gray-500 truncate">{documentFormatted(account.beneficiaryDocument)}</div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {account.status === 'DONE_PIX' ? (
                              <div className="text-gray-500">PIX</div>
                            ) : (
                              <>
                                <div className="text-gray-500">Banco: {(account.beneficiaryBankNumber)}</div>
                                <div className="text-gray-500">Agência: {(account.beneficiaryBankBranch)}</div>
                                <div className="text-gray-500">Conta {(account.beneficiaryBankAccount) + '-' + account.beneficiaryBankAccountDigit}</div>
                              </>
                            )
                            }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className={`inline-flex rounded-full bg-${getStatus(account.status).color}-100 px-2 text-xs font-semibold leading-5 text-${getStatus(account.status).color}-800`}>
                              <div className="text-gray-900">{currencyFormat(account.amount)}</div>
                            </span>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-500">{dateFormat((account.createdAt))}</div>

                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">Valor Salvo!</p>
                    <p className="mt-1 text-sm text-gray-500">O novo valor foi alterado com sucesso.</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
    </>
  );
}