import { useEffect } from "react";
import { useAuth } from "../hooks/auth";

export function Logout() {
  const { signOut } = useAuth();

  useEffect(() => {
    signOut();
  }, [])

  return null;
}