import React from 'react';
import Routes from "./routes";

import { Hooks } from './hooks';


function App() {
  return (
    <>
    <Hooks>
      <Routes />
    </Hooks>
    </>
  );
}

export default App;
