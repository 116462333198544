import React from "react";
import { AuthProvider } from './auth';




export function Hooks({children}: any) {
  return (
    <>
      <AuthProvider>
        {children}
      </AuthProvider>
    </>
  );
}